import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper'
import { Navigation, Pagination, FreeMode } from 'swiper/modules'

export default class extends Controller {
  static values = {
    options: Object
  }

  connect () {
    this.notEnoughSlides()

    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue
    })
  }

  disconnect () {
    this.swiper.destroy()
    this.swiper = undefined
  }

  get defaultOptions () {
    return {
      modules: [Navigation, Pagination, FreeMode],
      freeMode: {
        enabled: true,
        minimumVelocity: 0.2,
        momentum: false
      },
      slidesPerView: 4.2,
      spaceBetween: 10,
      grabCursor: true
    }
  }

  notEnoughSlides() {
    const slides = this.element.querySelectorAll('.swiper-slide')
    
    const isMobile = window.innerWidth <= 1024
  
    const maxSlides = isMobile ? 1 : 3
  
    if (slides.length < maxSlides) {
      this.element.classList.add('not-enough-slides')
    } else {
      this.element.classList.remove('not-enough-slides')
    }
  }
}
