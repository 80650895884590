/* eslint no-console:0 */

// MODULES =====================================================================
import "@hotwired/turbo-rails"
import ImageObjectFit from "../scripts/utils/image-object-fit"
import ConstantHeight from "../scripts/utils/constant-height"

// Stimulus
import "../scripts/controllers"

// CONFIGURATION ===============================================================
document.addEventListener("turbo:load", () => {
  new ImageObjectFit()
  new ConstantHeight()
})
