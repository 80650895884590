import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
import sharedControllers from './shared/index.js'
import ScrollReveal from '@stimulus-components/scroll-reveal'
import Reveal from '@stimulus-components/reveal'
import { Tabs } from 'tailwindcss-stimulus-components'

const stimulus = Application.start()
// stimulus.debug = process.env.NODE_ENV === 'development'

const controllers = import.meta.glob('./*_controller.js', { eager: true })
registerControllers(stimulus, { ...controllers, ...sharedControllers })

stimulus.register('scroll-reveal', ScrollReveal)
stimulus.register('reveal', Reveal)
stimulus.register('tabs', Tabs)
