import { Controller } from "@hotwired/stimulus"
import { gsap, Power4, CSSPlugin } from "gsap/all"

gsap.registerPlugin(CSSPlugin)

export default class extends Controller {
  static targets = ["content", 'icon']

  static values = {
    duration: Number,
    isOpen: Boolean
  }

  connect () {
    this.animationDuration = this.durationValue || 0.4
    this.isOpen = this.isOpenValue || false
    this.isAnimating = false

    // Initial state
    this.isOpen ? this.openCollapseAnimation(0) : this.closeCollapseAnimation(0)
  }

  handleClickButton () {
    !this.isOpen ? this.openCollapseAnimation(this.animationDuration) : this.closeCollapseAnimation(this.animationDuration)
    this.isOpen = !this.isOpen
  }

  clickOutsideHandler (e) {
    if (!this.element.contains(e.target)) {
      this.closeCollapseAnimation(this.animationDuration)
    }
  }

  closeCollapseAnimation (duration) {
    const tl = new gsap.timeline()

    tl.to(this.contentTarget, { overflow: 'hidden', duration: 0 })
    tl.to(this.contentTarget, { height: 0, ease: Power4.easeOut, duration: duration }, 'start')

    if (this.hasIconTarget) {
      tl.to(this.iconTarget, { rotate: 0, ease: Power4.easeOut, duration: duration }, 'start')
    }
  }

  openCollapseAnimation (duration) {
    const tl = new gsap.timeline()

    if (this.hasIconTarget) {
      tl.to(this.iconTarget, { rotate: 180, ease: Power4.easeOut, duration: duration }, 'start')
    }

    tl.to(this.contentTarget, { height: 'auto', ease: Power4.easeOut, duration: duration }, 'start')
    tl.to(this.contentTarget, { overflow: 'visible', duration: 0 })
  }
}
