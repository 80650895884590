import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static target = [
    "v2Anchor",
    "v3Anchor"
  ]

  connect() {
    this.isV2ScriptInjected = false
    this.isV3ScriptInjected = false
  }

  v2AnchorTargetConnected(el) {
    if (!this.isV2ScriptInjected) {
      this.isV2ScriptInjected = true
      this.#injectScript("explicit")

      // Taken from https://developers.google.com/recaptcha/docs/loading
      //
      // How this code snippet works:
      // This logic overwrites the default behavior of `grecaptcha.ready()` to
      // ensure that it can be safely called at any time. When `grecaptcha.ready()`
      // is called before reCAPTCHA is loaded, the callback function that is passed
      // by `grecaptcha.ready()` is enqueued for execution after reCAPTCHA is
      // loaded.
      if (typeof grecaptcha === 'undefined') {
        grecaptcha = {}
      }
      grecaptcha.ready = function(cb) {
        if (typeof grecaptcha === 'undefined') {
          // window.__grecaptcha_cfg is a global variable that stores reCAPTCHA's
          // configuration. By default, any functions listed in its 'fns' property
          // are automatically executed when reCAPTCHA loads.
          const c = '___grecaptcha_cfg'
          window[c] ||= {}
          window[c]['fns'] ||= []
          window[c]['fns'].push(cb)
        } else {
          cb()
        }
      }
    }

    grecaptcha.ready(() => {
      grecaptcha.render(el, { siteKey: el.dataset.siteKey })
    })
  }

  v3AnchorTargetConnected(el) {
    if (this.isV3ScriptInjected) return

    this.isV3ScriptInjected = true
    this.#injectScript(this.#siteKey(el.closest('form')))
  }

  async executeV3(event) {
    event.preventDefault()
    const form = event.target.closest('form')

    if (!form.reportValidity()) return

    const response = await new Promise((resolve) => {
      grecaptcha.ready(async () => {
        resolve(await grecaptcha.execute(this.#siteKey(form), { action: this.#recaptchaActionName(form) }))
      })
    })

    this.#hiddenInput(form).value = response
    form.requestSubmit()
  }

  #injectScript(renderVal) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `https://www.google.com/recaptcha/api.js?render=${renderVal}`
    document.body.appendChild(script)
  }

  #recaptchaActionName(form) {
    return this.#hiddenInput(form).name.match(/g-recaptcha-response-data\[(.+)\]/)[1]
  }

  #hiddenInput(form) {
    return form.querySelector("[id^='g-recaptcha-response-data-']")
  }

  #siteKey(form) {
    return this.#hiddenInput(form).dataset.sitekey
  }
}
