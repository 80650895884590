import { Controller } from "@hotwired/stimulus"

import "leaflet/dist/leaflet.css"
import "mapbox-gl/dist/mapbox-gl.css"
import L from "leaflet"
import 'mapbox-gl-leaflet'

export default class extends Controller {
  static values = {
    mapCenter: Object,
    markerPosition: Object
  }

  connect () {
    this.map = L.map(this.element, {
      center: this.mapCenterValue,
      zoom: 12,
      scrollWheelZoom: false
    })

    L.mapboxGL({
      style: 'https://api.maptiler.com/maps/73b3fa62-5f0d-4c07-81ac-15d1cdf7576e/style.json?key=bxcrVqtU886vRzzW6eJw',
      accessToken: 'pk.eyJ1Ijoic2hiY29tcGFyZXQiLCJhIjoiY2tuZzFma2xlMWR2MTJvbzd3OXUyZTJtYyJ9.gxgdOHGfnUEzMvoM4aoU6g'
    }).addTo(this.map)

    this.addCustomMarker()
  }

  addCustomMarker () {
    const myIcon = L.divIcon({ className: 'flex justify-center', html: `<i class="fas fa-map-marker-alt text-primary-500" style="font-size: 35px"></i>`, iconAnchor: [13.125, 35] })
    L.marker(this.markerPositionValue, { icon: myIcon }).addTo(this.map)
  }
}
